import React, { useEffect, useLayoutEffect, useState } from "react";
import "./menu-categories.css";
import "../base.css";
import Dish from "./Dish";
import { Collapse } from "react-collapse";
import { Accessible } from "./Accesible";
import { useDispatch, useSelector } from "react-redux";
import { cartAction } from "../../store/cartSlice";
import mixpanel from "mixpanel-browser";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useDidMountEffect from "../../hooks/SkipInitialRender";
import {
  S3_MENU_FOLDER,
  S3_MENU_THUMBNAIL_FOLDER,
} from "../../store/constants";
import { setCartData } from "../../store/cartActions";
import { chefActions } from "../../store/chefSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import ChefName from "../chef/ChefName";
import { capitalizeFirstLetter } from "../../utils/helper";

const Category = (props) => {
  const dispatch = useDispatch();
  const tilesClicked = useSelector((state) => state.dish.tilesClicked);
  const menuCategorieId = useSelector((state) => state.chef.menuCategorieId);
  const from = useSelector((state) => state.bottomBar.from);
  const dishesTab = useSelector((state) => state.bottomBar.dishes);
  const user = useSelector(({ auth }) => auth.user);
  const chefList = useSelector((state) => state.chef.chefList);
  const freeMenuidsOpened = useSelector(
    (state) => state.auth.freeMenuidsOpened
  );
  const { menuCartData, cartData } = useSelector(({ cart }) => cart);
  const menuIds = user.phonenumber ? user.menuIdsOpened : freeMenuidsOpened;

  let [isOpen, setIsOpen] = useState(false);
  let [locked, setLocked] = useState(false);

  let displayCart = useSelector((state) => state.bottomBar.cart);

  const dishes = props.dishes;
  const toggleCategory = (categoryid) => {
    isOpen = setIsOpen(!isOpen);
    if (!isOpen) {
      dispatch(chefActions.onMenuCategorieId(categoryid));
      mixpanel.track("menu-category-opened");
    }
  };

  useEffect(() => {
    if (props.categoryid === menuCategorieId && ["login", "explore"].includes(from)) {
      toggleCategory(props.categoryid)
      dispatch(bottomBarActions.updateFrom({ from: "" }));
    }

  }, [props.categoryid, menuCategorieId, from]);

  useDidMountEffect(() => {
    setIsOpen(!isOpen);
  }, [displayCart]);

  // useEffect(() => {
  //   setLocked(props.menuId ? !menuIds.includes(props.menuId) : false);
  // });

  // useLayoutEffect(() => {
  //   if (!isOpen) {
  //     dispatch(cartAction.resetTotalCost());
  //   }
  // }, [dispatch, isOpen]);
  const lockedUnlockedNotify = () =>
    toast.info("Unlock the chef's menu to explore", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: "info1",
    });
  let isPriceBandVisible = true;

  const chefInfo = chefList.find((c) => c._id === props.dishes[0].chefId)

  return (
    <Collapse isOpened={true}>
      {isOpen ? (
        <li className="collapsible-item active" id={props.categoryid}>
          <div
            className="collapsible-header"
            style={{columnGap: 5}}
            onClick={() => toggleCategory(props.categoryid)}
          >
            <div className="title-dish">
              <h5 className="title-dish-header">
                {dishesTab && tilesClicked
                  ? capitalizeFirstLetter(props.cuisine) +
                  " - " +
                  props.name +
                  " by Chef " +
                  props.dishes[0].chefName
                  : props.name}
                {dishesTab && tilesClicked && <ChefName name={chefInfo?.name} shift={chefInfo?.shift} leave={chefInfo?.leave} isSwitchOff={chefInfo?.isSwitchOff}  schedules={chefInfo?.schedules} breakShift={chefInfo?.breakShift} showOnlyDot />}
              </h5>
              <p className="count-item">
                <span className="num">{props.dishes.length}</span>items
              </p>
            </div>
            <div className="icon-down-div">
              {/* {dishesTab && tilesClicked && (
                <img
                  className="menu-locked-unlocked"
                  src={
                    locked
                      ? "images/Icons/Icon-04.svg"
                      : "images/Icons/Icon-05.svg"
                  }
                  alt=""
                />
              )} */}
              <img
                src="images/icon-collapse.svg"
                alt="icon-collapse"
                loading="lazy"
              />
            </div>
          </div>
          <div className="collapsible-body">
            <div className="dish-wrapper-list">
              {dishes.map((dish, i) => (
                <Dish
                  key={dish._id}
                  id={dish._id}
                  name={dish.name}
                  category={props.category}
                  price={dish.price}
                  type={dish.type}
                  img={dish.img ? S3_MENU_THUMBNAIL_FOLDER + dish.img : ""}
                  mainImg={dish.img ? S3_MENU_FOLDER + dish.img : ""}
                  description={dish.description}
                  saff={props.saff}
                  dish={dish}
                  onCheckPrice={props.onCheckPrice}
                  calculateTotal={false}
                  isFromCart={false}
                  setShowAddedItemPopup={props.setShowAddedItemPopup}
                  parentComponent={props.parentComponent}
                  isScheduleActive={true}
                  unAvailable={dish.unavailable}
                  isPriceBandVisible={isPriceBandVisible}
                  isAddedOnDiscount={false}
                  isFromOrderSummary={false}
                />
              ))}
            </div>
          </div>
        </li>
      ) : (
        <li
          className="collapsible-item"
          id={props.categoryid}
          onClick={() =>
            locked ? lockedUnlockedNotify() : toggleCategory(props.categoryid)
          }
        >
          <div style={{columnGap: 5}} className="collapsible-header">
            <div
              className="title-dish"
            // onClick={!locked && lockedUnlockedNotify}
            >
              <h5 className="title-dish-header">
                {dishesTab && tilesClicked
                  ? capitalizeFirstLetter(props.cuisine) +
                  " - " +
                  props.name +
                  " by Chef " +
                  props.dishes[0].chefName
                  : props.name}
                {dishesTab && tilesClicked && <ChefName name={chefInfo?.name} shift={chefInfo?.shift} leave={chefInfo?.leave} isSwitchOff={chefInfo?.isSwitchOff} schedules={chefInfo?.schedules} breakShift={chefInfo?.breakShift} showOnlyDot />}
              </h5>
              <p className="count-item">
                <span className="num">{props.dishes.length}</span>items
              </p>
            </div>
            <div className="icon-down-div">
              {/* {dishesTab && tilesClicked && (
                <img
                  className="menu-locked-unlocked"
                  src={
                    locked
                      ? "images/Icons/Icon-04.svg"
                      : "images/Icons/Icon-05.svg"
                  }
                  alt=""
                />
              )} */}
              <img
                src="images/icon-collapse.svg"
                alt="icon-collapse"
                loading="lazy"
              />
            </div>
          </div>
        </li>
      )}
      {/* toast */}
      <ToastContainer limit={1} />
    </Collapse>
  );
};

export default Category;
