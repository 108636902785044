import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";
import { API_TEST, PREP_TIME, S3_MENU_FOLDER } from "../../store/constants";
import { fetchDishBand } from "../../store/menuActions";
import "./exploreStory.css";
import { menuActions } from "../../store/menuSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import mixpanel from "mixpanel-browser";
import BackBtnSvg from "../../assets/ReactSvg/BackBtnSvg";
import { getDisplayChefTimeLabel, getTodayDateValue } from "../../utils/timeHelper";
import moment from "moment";
import axios from "axios";
import ImageLoader from "../chef/ImageLoader";

const ExploreStory = (props) => {
  const { categoryImg, categoryId, name, headers, description = "", chefImg, chefName, chefId, action, isPaused, isActive, labels, labelDesc } = props;
  const chefTimeData = useSelector((state) => state.chef.chefTime);
  const currentChefsTime = chefTimeData[chefId]
  const chefWaitTime = currentChefsTime?.chefWaitTime || 0

  const isServiceable = useSelector((state) => state.auth.isServiceable);
  const delivery_duration = useSelector(
    ({ auth }) => auth.deliveryDetails?.delivery_duration?.value
  );
  const delivery_Time = delivery_duration ? Math.round(delivery_duration / 60) : 10;
  const chefList = useSelector((state) => state.chef.chefList);
  const currentChefDetails = chefList.find((c) => c._id === chefId) || {};

  const dispatch = useDispatch();
  const dish = useSelector(({ dish }) => dish);
  const [loaded, setLoaded] = useState(false);
  const onStoryBackClick = () => {

    dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
    mixpanel.track("menu-stories-back-clicked");
  };

  const fetchChefTimeApiCall = async () => {
    try {
      const response = await axios.get(
        `${API_TEST}getDeliveryDelayTimeByChef/${chefId}`
      );
      const responseData = response?.data?.data || {};
      // setChefWaitTime(responseData?.chefWaitTime || 0)
    } catch (error) {
      console.error("Error making API call:", error.message);
      throw error;
    }
  }

  useEffect(() => {
    // fetchChefTimeApiCall()
  }, [])

  const getTextForBand = (band) => {
    switch (band) {
      case "HIDDEN_GEM":
        return "Hidden Gems: 70-99% off";
      case "MIDDLE":
        return "Popular Platter: 50-70% off";
      case "TRENDING":
        return "Hot Buns: 40-50% off";
      case "NORMAL":
        return "No discount";
      default:
        break;
    }
  };

  const addToCard = () => {
    dispatch(chefActions.onMenuCategorieId(categoryId));
    dispatch(bottomBarActions.updateFrom({ from: "explore" }));
    dispatch(menuActions.setIsMenuClicked(true));
    dispatch(bottomBarActions.loadTab({ tabName: "menu" }));
    dispatch(chefActions.onMenuClick(props.chefId));
    mixpanel.track("go-to-menu-clicked");
  };

  const onChefNameClick = () => {
    dispatch(chefActions.onExploreChefClick(props.chefId));
    dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
  }

  useEffect(() => {
    if (!props.id) return
    dispatch(fetchDishBand(props.id));
  }, [dispatch]);


  useEffect(() => {
    action('pause');
    if (loaded && props.isActive) {
      action('play');
    }
  }, [loaded]);

  const imageLoaded = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 0);
  };

  const getStatusClassname = () => {
    if (!isServiceable) {
      return "chef-non-serviciable";
    } else if (todayChefSchedule?.online) {
      return "chef-online";
    } else {
      return "chef-away";
    }
  };

  const todayChefSchedule = getTodayDateValue(currentChefDetails?.schedules || []);

  // const getStatusLabel = () => {
  //   if (!isServiceable) {
  //     return "Pre-bookings only"
  //   } else if (currentChefDetails?.isSwitchOff) {
  //     return "Away on leave"
  //   } else if (!todayChefSchedule?.isAvailable) {
  //     return "Weekly off"
  //   } else if (todayChefSchedule?.online) {
  //     if (delivery_duration) {
  //       return `Delivery in ${chefWaitTime + delivery_Time} mins`;
  //     } else {
  //       return "Open now";
  //     }
  //   } else if (todayChefSchedule?.isAvailable) {
  //     return `Opens at ${moment(todayChefSchedule?.startTime, 'h:mm A').format('h a')}`
  //   }
  // }

  const textOutput = getDisplayChefTimeLabel(todayChefSchedule);

  // const formattedString = `${name} by Chef ${chefName}`;
  // const capitalizedSentence = formattedString.charAt(0).toUpperCase() + formattedString.slice(1);

  return (
    <div>
      <div className="image-container">
        {/* <div className="top-gradient"></div> */}
        <img
          src={S3_MENU_FOLDER + props.img}
          className="story-image-container"
          loading="lazy"
          // style={{height:"100vh"}}
          onLoad={imageLoaded}
        />
        {/* <div className="gradient"></div> */}
      </div>
      {!loaded &&
        <ImageLoader className="loader_wrapper explore_loader_wrapper" />
      }
      <div className="swiper_Top explore_swiper_Top">
        <div className="swiper_Title">
          <p className="exploreDishNameTitle" style={{ textTransform: "capitalize" }}>{`${name}`} <span style={{ color: "#D9D9D9" }}>{`\u2022`}</span> {`${headers}`}</p>
        </div>
        <div className="chef-info-name-wrapper">
          <div onClick={onChefNameClick} className="swiper_Title_img explore_swiper_Title_img">
            <img src={"https://cuirato-images.s3.ap-south-1.amazonaws.com/display-pic/chef3-dp.jpg" || chefImg} className="storyImg" />
          </div>
          <div>
            <p onClick={onChefNameClick} className="explore-chef-label" >Chef {chefName}</p>
            <div className="chef-time-wrapper">
              <div style={{ marginTop: 0 }} className="explore-chef-status-indicator">
                <div
                  className={getStatusClassname()}
                />
              </div>
              <span className="explore-chef-timing-label" style={{}}>
                {textOutput}
              </span>
            </div>
          </div>
          <button onClick={addToCard} className="explore-story-menu-button">Menu</button>
        </div>
        <div className="swiper_top_menu">
          <div className="swiper_top_back_btn">
          </div>
        </div>
      </div>
      <div style={{padding:0}} className="swipper_bottom">
      <div className="ingrident-list-top-wrapper" />
      <div className="explore-swipper_bottom">
        <div className="ingrident-list-wrapper">
          <div className="ingrident-list-header-label">
            {labels.map((l) => (
              <span className={(l.key === labelDesc.key) && "ingrident-list-header-label-active"}>{l.key}</span>
            ))}
          </div>
          <div>
            {description}
          </div>
        </div>
        <div className="explore_swipper_bottom_first">
          <div>
            <div className="items_value">
              <div className="plus_taxes">
                <h5 style={{ fontSize: 18 }}>
                  <span className="currency">&#8377;</span> {props.price}
                </h5>
              </div>
            </div>
            {dish.dishBand ? (
              <div className="explore-offer-label-wrapper">
                <h6 style={{ fontSize: 12, fontWeight: 300 }} className="media-dish-cap orange-text">
                  {getTextForBand("HIDDEN_GEM")}
                </h6>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="addcart_btn explore-addcart_btn">
            <button onClick={addToCard}>
              <span style={{ marginRight: 5 }}>+</span>
              <p style={{ fontSize: "18px" }}>ADD</p>
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreStory;
