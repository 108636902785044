import React from "react";
import "./chef-card-slider.css";
import { isIOSDevice } from "../../utils/helper";
import { usePWAInstall } from "react-use-pwa-install";

const ChefCardSlider = (props) => {
  let install = usePWAInstall();
  const urlParams = new URLSearchParams(window.location.search);
  const isWebview = urlParams.get('webview');
  const isVisible = localStorage.getItem("installAppVisible") === "false" ? false : true

  function isInstalled() {
    if (window.navigator.standalone) return true;
    if (window.matchMedia("(display-mode: standalone)").matches) return true;
    return false;
  }

  const isShowPwa = () => {
    if (isIOSDevice()) {
      return !isInstalled() && !isWebview && isVisible
    } else {
      return !isInstalled() && !isWebview && install && isVisible
    }

  }

  const isShowPwaVisible = isShowPwa();

  return (
    <div style={!isShowPwaVisible ? { position: "relative" } : { position: "relative", marginBottom: 50 }} className="slick-item">
      <div className="slider-item">{props.children}</div>
    </div>
  );
};

export default ChefCardSlider;
