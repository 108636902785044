import moment from 'moment';

function isBefore4AM(timeStr) {
  const start = moment('12:00 AM', 'hh:mm A');
  const end = moment('4:00 AM', 'hh:mm A');
  const checkTime = moment(timeStr, 'hh:mm A');

  return checkTime.isBetween(start, end, null, '[]');
}

const getNextObjectByIndex = (arr, index) => {
  if (arr.length === 0) {
    return null; // Handle empty array
  }

  if (index < arr.length - 1) {
    // If the index is not the last, return the next object
    return arr[index + 1];
  } else {
    // If the index is the last, return the first object
    return arr[0];
  }
}

export const getTodayDateValue = (schedules) => {
  // const date = new Date()
  // date.setHours(date.getHours() - 5);
  const currentDayOfWeek = moment().subtract({ 'hours': 4 }).day();
  const currentTime = moment();
  const isMidnightTime = isBefore4AM(moment().format("hh:mm A"))

  const todaySchedule = schedules.find((schedule) => {
    const scheduleDayOfWeek = moment(schedule.dayOfWeek, 'dddd').day();
    // const startTime = isBefore430AM(schedule.endTime)
    //   ? moment(schedule.startTime, 'hh:mm A').subtract(1, 'day')
    //   : moment(schedule.startTime, 'hh:mm A');
    // const endTime = moment(schedule.endTime, 'hh:mm A');

    return scheduleDayOfWeek === currentDayOfWeek;
  });
  if (isMidnightTime && !todaySchedule?.online) {
    const index = schedules.findIndex(a => a?._id === todaySchedule?._id)
    return getNextObjectByIndex(schedules, index)
  } else {
    return todaySchedule || null;
  }


  // let todaySchedule = null;

  // schedules.forEach((schedule) => {
  //     const scheduleDayOfWeek = moment(schedule.dayOfWeek, 'dddd').day();
  //     const startTime = isBefore430AM(schedule.endTime) ? moment(schedule.startTime, 'hh:mm A').subtract(1, 'day') : moment(schedule.startTime, 'hh:mm A');
  //     const endTime = moment(schedule.endTime, 'hh:mm A');
  //     // console.log('currentDayOfWeek', currentDayOfWeek, scheduleDayOfWeek, schedule.dayOfWeek, isBefore430AM(schedule.endTime))
  //     // debugger
  //     // startTime.setHours(startTime.getHours() - 5);
  //     // endTime.setHours(endTime.getHours() - 5);
  //     if (scheduleDayOfWeek === currentDayOfWeek) {
  //         todaySchedule = schedule;
  //     }
  // });

  // return todaySchedule;
};

function formatTime(originalTime) {  
  let time = originalTime.toLowerCase().replace("00", "");

  if (time.endsWith("am") || time.endsWith("pm")) {
    time = time.replace("am", "am").replace("pm", "pm");
  }
  if(originalTime.includes(":00")){
    return time.replace(":", "")
  }
  return time;
}

export const getDisplayChefTimeLabel = (todayChefSchedule) => {
  const { isAvailable } = todayChefSchedule || {};
  if(!isAvailable) return "Weekly off"
  const startTime = todayChefSchedule?.startTime?.replace(" ", "").toLowerCase();
  const endTime = todayChefSchedule?.endTime?.replace(" ", "").toLowerCase();
  if(!startTime || !endTime) return "Not available"
  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);
  const textOutput = `${formattedStartTime} to ${formattedEndTime}`;
  return textOutput

}

export const formatScheduleTime = (schedules) => {
  const schedule = schedules.map((time) => {
    const { dayOfWeek, startTime, endTime, isAvailable } = time;
    if (startTime && endTime) {
      const endTimeParsed = new Date(`2000-01-01 ${endTime}`);
      const startTimeParsed = new Date(`2000-01-01 ${startTime}`);

      if (endTimeParsed < startTimeParsed) {
        return { day: `${dayOfWeek.slice(0, 3)}`, time: `${startTime.toLowerCase()} – ${endTime.toLowerCase()}`, isAvailable };
      }

      return { day: `${dayOfWeek.slice(0, 3)}`, time: `${startTime.toLowerCase()} – ${endTime.toLowerCase()}`, isAvailable }
    } else {
      return { day: `${dayOfWeek.slice(0, 3)}`, time: `Not available`, isAvailable };
    }
  })
  return schedule
}

export const getDishBandLabel = (prepTime) => {
  if (prepTime < 40) {
    return "HIDDEN_GEM"
  } else if (prepTime < 60) {
    return "MIDDLE"
  } else if (prepTime < 80) {
    return "TRENDING"
  } else {
    return "NORMAL"
  }
}