import Banners from "./banner/Banners";
import ChefCards from "./chef/ChefCards";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import AppContainer from "./util/AppContainer";
import { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import { useDispatch, useSelector } from "react-redux";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import { loginActions } from "../store/loginSlice";
import OrderStatusBadge from "./dishBook/orderStatusBadge";
import Alert from "./util/alert/Alert";
import { bottomBarActions } from "../store/bottomBarSlice";
import {
  getPreLaunchOrder,
  reloadUser,
  updateUser,
} from "../store/loginActions";
import InstallApp from "./InstallApp/InstallPWA";
import { getToken } from "@firebase/messaging";
// import { messaging } from "../firebase";
import { ORDER_EXPIRY_TIME } from "../store/constants";
import { isWebApp } from "../utils/helper";

// TODO: replace the step object in global level
const steps = [
  // {
  //   content: <h5>Let's begin our journey!</h5>,
  //   locale: { skip: <strong aria-label="skip">Skip</strong> },
  //   placement: "center",
  //   target: "body",
  // placement: 'center',
  // },
  {
    title: "Swipe to browse chefs",
    content: "Stop scrolling endlessly, these chefs deserve your attention.",
    spotlightPadding: 10,
    target: ".profile-bottom",
    placement: "top",
    locale: {
      last: "Got it",
      next: "",
    },
    disableBeacon: true,
  },
  // {
  //   title: "Banners",
  //   content:
  //     "Read important information and announcements. Get the best deals!",
  //   floaterProps: {
  //     disableAnimation: true,
  //   },
  //   disableBeacon: true,
  //   spotlightPadding: 0,
  //   target: ".slick-list",
  // },
  // {
  //   title: "Chef profile",
  //   content:
  //     "Professionally trained and experienced to get your food right every single time.",
  //   spotlightPadding: 0,
  //   target: ".slick-active .org-slick-item",
  //   placement: "top",
  //   disableBeacon: true,
  //   disableScrolling: false,
  // },
  // {
  //   title: "Chef's menu stories",
  //   content: "Click here to check out image gallery of all the dishes",
  //   //spotlightPadding: 3,
  //   target: ".slick-active .cuisine-slider .cuisine-slider-item",
  //   placement: "top",
  //   locale: {
  //     last: "Got it",
  //     next: "",
  //   },
  //   showProgress: false,
  //   disableBeacon: true,
  //   disableScrolling: true,
  // },
];

const Home = (props) => {
  const dispatch = useDispatch();
  const [run, setRun] = useState(false);
  let tourGuid = useSelector((state) => state.auth.tourState);
  const chef = useSelector((state) => state.bottomBar.chef);
  const user = useSelector(({ auth }) => auth.user);
  const [autoSwipe, setAutoSwipe] = useState(false);
  const runDemo = useSelector((state) => state.chef.runDemo);
  const isChefScreen = useSelector((state) => state.bottomBar.chef);
  const activeOrder = useSelector((state) => state.auth.orderDetails);
  const [activeOrderType, setActiveOrderType] = useState("none");
  const [initialChefRender, setInitialChefRender] = useState(false);

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/home" });
    ReactGA.event({
      category: "tab",
      action: "home",
    });
    mixpanel.track("home");
    const loggedIn = localStorage.getItem("@user");
    // if(loggedIn){
    //   dispatch(bottomBarActions.showChef());
    // }else{
    //   dispatch(bottomBarActions.showExplore());
    // }
    dispatch(bottomBarActions.showExplore());
  }, []);


  useEffect(() => {
    dispatch(getPreLaunchOrder(user.userid));
  }, [user.userid]);

  useEffect(() => {
    isBadgeVisible();
  }, [activeOrder]);

  const isBadgeVisible = () => {
    if (activeOrder?.createdAt && activeOrder?.orderType == "order-now") {
      let { createdAt, updatedAt } = activeOrder;
      let orderedDate = new Date(updatedAt ? updatedAt : createdAt);
      orderedDate.setTime(
        orderedDate.getTime() + ORDER_EXPIRY_TIME * 60 * 60 * 1000
      );
      orderedDate = orderedDate.toLocaleString();
      let curDate = new Date();
      curDate = curDate.toLocaleString();

      if (orderedDate < curDate) {
        setActiveOrderType("none");
      } else {
        setActiveOrderType("order-now");
      }
    } else if (activeOrder?.orderType == "pre-launch") {
      setActiveOrderType("pre-launch");
    } else {
      setActiveOrderType("none");
    }
  };

  // useEffect(() => {
  //   let isJoyrideMoved = false;
  //   const observerCallback = (mutationsList, observer) => {
  //     for (const mutation of mutationsList) {
  //       if (mutation.type === 'childList') {
  //         // Check if the target element has been added
  //         if (mutation.addedNodes.length > 0) {
  //           const addedNode = mutation.addedNodes[0];
  //           if (addedNode.id === 'react-joyride-step-0') {
  //             // Find the Joyride content and the target .slick-item
  //             const joyrideStep = addedNode;
  //             const slickItem = document.querySelector('.slick-item');

  //             if (joyrideStep && slickItem) {
  //               const joyrideContent = joyrideStep;
  //               if (joyrideContent) {
  //                 slickItem.appendChild(joyrideContent);
  //                 isJoyrideMoved = true;
  //               }
  //             }

  //             // Disconnect the observer after the element is found and moved
  //             observer.disconnect();
  //           }
  //         }
  //       }
  //     }
  //   };

  //   // Create a MutationObserver
  //   const observer = new MutationObserver(observerCallback);

  //   // Observe changes to the body element (or a specific parent element)
  //   observer.observe(document.body, { childList: true, subtree: true });

  //   // Clean up the observer when the component unmounts
  //       return () => {
  //     observer.disconnect();

  //     if (isJoyrideMoved) {
  //       const joyrideStep = document.getElementById('react-joyride-step-0');
  //       if (joyrideStep && joyrideStep.parentNode) {
  //         joyrideStep.parentNode.removeChild(joyrideStep);
  //       }
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (runDemo) {
      // setTimeout(() => {
      if (!tourGuid) {
        setRun(true);
      }
      // }, 5000);
      mixpanel.track("demo-shown");
    }
  }, [runDemo]);

  useEffect(() => {
    if(!chef){
      setRun(false);
    }
  }, [chef]);

  const handleJoyrideCallback = (data) => {
    const { status, type, action } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (action == "close") {
      setRun(false);
      mixpanel.track("demo-closed");
      return;
    }
    if (finishedStatuses.includes(status)) {
      dispatch(loginActions.setTourState());
      setRun(false);
      mixpanel.track("demo-done");
    }
    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
    // if ([EVENTS.STEP_AFTER].includes(type)) {
    //   if (data.index == 0) {
    //     setAutoSwipe(0);
    //   }
    // }
    // if([EVENTS.STEP_BEFORE].includes(type)){
    //   if(data.index == 0){
    //     setTimeout(() => {
    //       setAutoSwipe(0);
    //     }, 500);
    //     mixpanel.track('demo-1');
    //   } else if(data.index == 1){
    //     mixpanel.track('demo-2');
    //   } else if(data.index == 2){
    //     const content = document.querySelector(".organisation-slider");
    //     const contentwidth = content.offsetWidth;
    //     mixpanel.track('demo-3');
    //   } else if(data.index == 3){
    //     const content = document.querySelector(".cuisine-slider");
    //     const contentwidth = content.offsetWidth;
    //     mixpanel.track('demo-4');
    //   }
    // }
  };

  // TODO  logic to send api call for firebase token
  // const generateToken = async () => {
  //   const token = await getToken(messaging, {
  //     vapidKey:
  //       "BBCPDwUEz-MJhJ8VJRBtFFTl5xM-EKOhPdyqkd5EB0Cn5OnNHJ0CrEcGig2sSJ2jqK6mHfyS7fLmU-EW99CW1-8",
  //   });
  //   if (token && user?.userid) {
  //     dispatch(updateUser(user, token, "token"));
  //   }
  // };

  // useEffect(() => {
  //   if (isWebApp() || !isChefScreen) return;
  //   if (!initialChefRender) {
  //     setInitialChefRender(true);
  //     if ("Notification" in window) {
  //       Notification.requestPermission().then((permission) => {
  //         if (permission === "granted") {
  //           if (Notification.permission === "granted") {
  //             generateToken();
  //           }
  //         } else if (permission === "denied") {
  //           console.log("Notification permission denied.");
  //         }
  //       });
  //     }
  //   }
  // }, [isChefScreen]);

  const showOrderStatusBadge =
    activeOrderType == "order-now" || activeOrderType == "pre-launch";

  return (
    <AppContainer id={props.id}>
      <div
        className={
          activeOrderType == "order-now" || activeOrderType == "pre-launch"
            ? "pb-150"
            : ""
        }
      >
        {/* {isServiceableAlert && <Alert />} */}
        <TopBar />
        <Banners />
        <ChefCards autoSwipe={autoSwipe} />
        {!showOrderStatusBadge && <InstallApp />}
        <OrderStatusBadge
          visible={showOrderStatusBadge}
          orderType={activeOrderType}
        />
        <BottomBar />

        <Joyride
          disableScrollParentFix={true}
          callback={handleJoyrideCallback}
          continuous={true}
          run={run}
          scrollToFirstStep={true}
          showProgress={false}
          showSkipButton={false}
          steps={steps}
          disableOverlay={true}
          hideBackButton={true}
          disableScrolling={true}
          styles={{
            options: {
              arrowColor: "#F8EDD5",
              primaryColor: "#e8814d",
              zIndex: 10000,
              backgroundColor: "#F8EDD5",
              overlayColor: "rgba(0, 0, 0, 0.6)",
            },
            tooltipTitle: {
              fontSize: 16,
              margin: "0 0 0px 0",
              textAlign: "left",
              paddingLeft: "10px",
              color: "#670000",
            },
            tooltipContent: {
              fontSize: 13,
              padding: "10px 10px",
              textAlign: "left",
              fontWeight: 300,
              color: "#670000",
            },
            buttonNext: {
              fontSize: "13px",
            },
          }}
        />
      </div>
    </AppContainer>
  );
};

export default Home;
