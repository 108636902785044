import "./install-pwa.css";
import { usePWAInstall } from "react-use-pwa-install";
import PWAPrompt from "react-ios-pwa-prompt";
import { useState } from "react";
import { isIOSDevice } from "../../utils/helper";

const InstallApp = () => {
  let install = usePWAInstall();
  const [isVisible, setIsVisible] = useState(
    localStorage.getItem("installAppVisible") === "false" ? false : true
  );
  const [isVisibleIosInstall, setIsVisibleIosInstall] = useState(false);

  function isInstalled() {
    // For iOS
    if (window.navigator.standalone) return true;
    // For Android
    if (window.matchMedia("(display-mode: standalone)").matches) return true;
    // If neither is true, it's not installed
    return false;
  }

  const handleDismiss = () => {
    setIsVisible(false);
    localStorage.setItem("installAppVisible", false);
  };

  const handleClick = () => {
    // pwaInstall({
    //   title: "Install Web App",
    //   logo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIUAAACFCAMAAABCBMsOAAAAYFBMVEU8Wpn///8yU5aCkriIl7zY3OgaRI81VZdpfax+jrb8/P13ibM5WJggSJDHzd7z9PhWbaMqTpOxutFyhLCWo8Ofqse5wdbj5u7Ax9qPnb+rtM5gdqhIY57N0+FNZ6AMP4yOGPU0AAACcklEQVR4nO3c2ZKqMBAGYDYNYjIhMBgw6Lz/Wx5nygVIWApCJ3Wq/2slnw5L09IThD4kcA34Cyo+8U3BiySGTFJwTZF9H0UEG3asBopMiQA+Iu0rvl0gHoyqq+BHJ4iAsqyjKNx8FUHALh1FEjlSRE1HETtTxKhABSpQgYpuKCV/ob9xoqCCiVal5SNpqlTeBhFjTF9lRwUVQV7dJOfZM5xzKeWhbrRldlPQY1vI0Jgbg1IwdTMTHvkCUpDgmo0ioBTR/TBugFIINWUAUohyGgGiIDmfIsAoKBs5PkEVophDAChIPosAUIgvDxRUTZytwBRsfq8AUBznjlIIBZk7YYEoxHVs5d/i4pV6b8XI5fySlCp/564VfpYVxt3iwtiz7KQjxadVBRUmRKX9AXZW3A0IuaBNZVVBUoNiyUatKqKTjuDt2E3IborEoFjSN9xdsWS32F9xQAUqUIEKVHipIL0Ik+KHDGNb0aa9qEpXyDwdxrIiOuurzke2PigOev3rQFHrv1Q6UFy9UOitXxeKUj9UHSgCvSh3oPgxbAhcwb1QmC4s8Aq9e+FCURkWgVc0tq+paxSZ4XQBr+DKdi9nlcK4IWiF8R5+k4KsUBjr0E0K2sanbs6Gdqcsey85pYad03L1Gxs++6D6NfaX/os7AVSgAhWoQAUqUIEKVKACFahABSpQgQpUoAIVPim2zmSuV3RnMov5ZyD3UbC6o9g6q7tW0Z/VDattw7prFezZP39NkqdiwXOQlhWUvZ6cfk/VV4xFZPVourkbP/UWEjH2/iXhM9ufXZrVc/qnIswGCeV5+j1N/Xmm37f/c+AyqPjED8U/xXs5J4jF09YAAAAASUVORK5CYII=",
    //   features: (
    //     <ul>
    //       <li>Cool feature 1</li>
    //       <li>Cool feature 2</li>
    //       <li>Even cooler feature</li>
    //       <li>Works offline</li>
    //     </ul>
    //   ),
    //   description: "This is a very good app that does a lot of useful stuff. ",
    // })
    //   .then(() => {
    //     install();
    //   })
    //   .catch(() => alert("User opted out from installing"));
  };

  const renderInstallAppContent = (isIos) => {
    return (
      <div className="installApp_container">
        <span onClick={handleDismiss}>X</span>
        <span className="installApp_text">
          Would you like to install <span className="hero_text">Cuirato</span>{" "}
          on your phone ? 
        </span>
        {isIos ? (
          <span
            className="installlApp_btn"
            onClick={() => setIsVisibleIosInstall(true)}
          >
            Install
          </span>
        ) : (
          <span className="installlApp_btn" onClick={install}>
            Install
          </span>
        )}
      </div>
    );
  };

  const urlParams = new URLSearchParams(window.location.search);
  const isWebview = urlParams.get('webview');

  return (
    <>
      {isIOSDevice() ? (
        <>
          {!isInstalled() && !isWebview && isVisible && <>{renderInstallAppContent(true)}</>}
        </>
      ) : (
        <>
          {!isInstalled() && !isWebview && install && isVisible && (
            <>{renderInstallAppContent(false)}</>
          )}
        </>
      )}
      {isVisibleIosInstall && (
        <PWAPrompt
          timesToShow={100}
          delay={200}
          copyClosePrompt="Close"
          onClose={() => setIsVisibleIosInstall(false)}
          permanentlyHideOnDismiss={false}
        />
      )}
    </>
  );
};

export default InstallApp;