import React, { useState } from 'react'
import BannerWrapper from '../../banner/BannerWrapper'
import Slider from 'react-slick'
import Banner from '../../banner/Banner'
import "./vegchoice.css"

const VegChoice = ({ setFoodChoiceState }) => {
    const [gif1, setGif1] = useState("images/intro-banner-chef-profile.gif");
    const [gif2, setGif2] = useState("images/intro-banner-cost.gif");

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => {
            if (current - next < 0) {
                setGif1("");
                setGif2("");
            } else {
                setGif1("");
                setGif2("");
            }
        },
    };

    return (
        <div className="explore-login-container">
            <BannerWrapper className="banner-wrapper">
                <Slider {...settings}>
                    <div className="banner-login-slider">
                        <Banner src="images/intro-banner-1.jpg" />
                        <h2 className="container-title" align="center">
                            What's your preference?
                        </h2>
                    </div>
                </Slider>
            </BannerWrapper>
            <div className="banner-box">
                <div className="button-box">
                    <a
                        onClick={() => {
                            localStorage.setItem("foodChoice", "VEG")
                            setFoodChoiceState("VEG")
                        }}
                        style={{ background: "#7d9a6f" }} className="btn-login" >
                        <span>Veg</span>
                    </a>
                    <a
                        className="btn-login"
                        onClick={() => {
                            localStorage.setItem("foodChoice", "NONVEG")
                            setFoodChoiceState("NONVEG")
                        }}
                    >
                        <span>Non veg</span>
                    </a>

                </div>
            </div>
        </div>
    )
}

export default VegChoice