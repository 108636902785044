import axios from "axios";
import { bottomBarActions } from "./bottomBarSlice";
import { cartAction } from "./cartSlice";
import { chefActions } from "./chefSlice";
import {
  MSG91_AUTH_KEY,
  MSG91_SMS_CHECKOUT_TEMPLATE_ID,
  MSG91_SMS_SENDER_ID,
  MSG91_SMS_TEMPLATE_ID,
  MSG91_URL,
} from "./constants";
import { loginActions } from "./loginSlice";
import mixpanel from "mixpanel-browser";

export const registeration = (user, setError, chefId) => async (dispatch) => {
  try {
    const { data } = await axios.post("user", user);
    if (data.statusCode == 200) {
      const userData = {
        userid: data.data._id,
        username: data.data.name,
        phonenumber: data.data.phoneNumber,
        menuOpens: data.data.menuOpens,
        menuIdsOpened: data.data.menuIdsOpened,
        dishPriceChecked: data.data.dishPriceChecked,
        dishIdsChecked: data.data.dishIdsChecked,
        email: data.data.email,
        area: data.data.area,
        referral: data.data.referral,
        referralCode: data.data.referralCode,
        maxDishes: data.data.maxDishes,
        maxChefs: data.data.maxChefs,
        maxDishPrices: data.data.maxDishPrices,
        totalDishesBooked: data.data.totalDishesBooked,
        freeDishes: data.data.freeDishes,
        type: data.data.type,
        addressLine1: data.data.addressLine1,
      };
      if (data.data.statusCode == 405) {
        dispatch(loginActions.setGetReferral());
        setError(true);
      } else if (data.data.name) {
        dispatch(loginActions.setLoggedIn({ loggedIn: true, userData }));
        dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
        mixpanel.track("login_successful");
        let mpUserId = localStorage.getItem("mpUserId");
        if (!(mpUserId === userData.userid)) {
          mixpanel.alias(userData.userid, mpUserId);
          mixpanel.people.set({
            name: userData.username,
            phoneNumber: userData.phonenumber,
          });
        }
        if(chefId){
          dispatch(bottomBarActions.updateFrom({ from: "login" }));
        }
      } else {
        dispatch(loginActions.setGetName({ userData }));
      }
      
    } else if (data.statusCode == 400) {
      console.log("error");
      //TODO: show error page
    }
  } catch ({ ...error }) {
    dispatch(loginActions.setLoginError({ loggedIn: false, error }));
  }
};

export const login = (user, chefId) => async (dispatch) => {
  try {
    const { data } = await axios.post("user/login", user);
    if (data.statusCode == 200) {
      const userData = {
        userid: data.data._id,
        username: data.data.name,
        phonenumber: data.data.phoneNumber,
        menuOpens: data.data.menuOpens,
        menuIdsOpened: data.data.menuIdsOpened,
        dishPriceChecked: data.data.dishPriceChecked,
        dishIdsChecked: data.data.dishIdsChecked,
        email: data.data.email,
        area: data.data.area,
        referral: data.data.referral,
        referralCode: data.data.referralCode,
        maxDishes: data.data.maxDishes,
        maxChefs: data.data.maxChefs,
        maxDishPrices: data.data.maxDishPrices,
        totalDishesBooked: data.data.totalDishesBooked,
        type: data.data.type,
        addressLine1: data.data.addressLine1,
      };
      if (data.data.name) {
        dispatch(loginActions.setLoggedIn({ loggedIn: true, userData }));
        dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
        mixpanel.track("login_successful");
        let mpUserId = localStorage.getItem("mpUserId");
        if (!(mpUserId === userData.userid)) {
          mixpanel.alias(userData.userid, mpUserId);
          mixpanel.people.set({
            name: userData.username,
            phoneNumber: userData.phonenumber,
          });
        }
        if(chefId){
          dispatch(bottomBarActions.updateFrom({ from: "login" }));
        }
      } else {
        dispatch(loginActions.setGetName({ userData }));
        mixpanel.track("login_successful-new_signup");
      }
     
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    dispatch(loginActions.setLoginError({ loggedIn: false, error }));
  }
};

export const getOpenPaymentToken = (paymentId, amount, phonenumber) => async (dispatch) => {
  const data = await axios.get('getOpenPaymentToken/' + paymentId + "/" + amount + "/" +  phonenumber);
  dispatch(loginActions.setOpenPaymentToken({ data }));
}

export const reloadUser = (userId) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axios.get("user/" + userId);
      if (response.status != 200) {
        throw new Error("Could not fetch User");
      }
      const data = response.data;
      const userData = {
        userid: data.data._id,
        username: data.data.name,
        phonenumber: data.data.phoneNumber,
        menuOpens: data.data.menuOpens,
        menuIdsOpened: data.data.menuIdsOpened,
        dishPriceChecked: data.data.dishPriceChecked,
        dishIdsChecked: data.data.dishIdsChecked,
        email: data.data.email,
        area: data.data.area,
        referral: data.data.referral,
        referralCode: data.data.referralCode,
        maxDishes: data.data.maxDishes,
        maxChefs: data.data.maxChefs,
        maxDishPrices: data.data.maxDishPrices,
        totalDishesBooked: data.data.totalDishesBooked,
        freeDishes: data.data.freeDishes,
        type: data.data.type,
        addressLine1: data.data.addressLine1,
      };
      return userData;
    };

    try {
      const userData = await fetchData();
      dispatch(loginActions.setLoggedIn({ loggedIn: true, userData }));
      if (!userData.username) {
        dispatch(loginActions.setGetName({ userData }));
      }
    } catch (error) {
      console.log("error while calling user-fetchdata");
      // show error on ui
    }
  };
};

export const setBookingData = (userId) => async (dispatch) => {
  try {
    if (userId) {
      const { data } = await axios(`bookings/${userId}`);
      if (data.data) dispatch(loginActions.setBooking({ booking: data.data }));
    }
  } catch ({ ...error }) {
    console.log(error);
  }
};

export const getPreLaunchOrder = (userId) => async (dispatch) => {
  try {
    if (userId) {
      const { data } = await axios(`order/active/${userId}`);
      if(data.data.length > 0) dispatch(loginActions.setOrderDetails(data.data[0]));
    }
  } catch ({ ...error }) {
    console.log(error);
  }
};

export const setAddressData =
  (userId, newAddress, isOrderUpdate) => async (dispatch) => {
    try {
      if (userId) {
        let address = newAddress;
        const { data } = await axios.post(`address/${userId}`, address);
        if (data.statusCode === 200) {
          dispatch(loginActions.setAddress(address));
          isOrderUpdate
            ? dispatch(bottomBarActions.loadTab({ tabName: "checkout" }))
            : dispatch(bottomBarActions.loadTab({ tabName: "cart" }));
        }
      }
    } catch ({ ...error }) {
      console.log(error);
    }
  };

export const setAddressForPreOrder =
  (userId, newAddress) => async (dispatch) => {
    try {
      if (userId) {
        let address = newAddress;
        const { data } = await axios.post(`address/${userId}`, address);
        if (data.statusCode === 200) {
          dispatch(loginActions.setAddress(address));
        }
      }
    } catch ({ ...error }) {
      console.log(error);
    }
  };

export const addWaitingList =
  (phoneNumber, enableButton) => async (dispatch) => {
    try {
      if (phoneNumber) {
        let user = { phoneNumber: phoneNumber };
        const { data } = await axios.post(`user/waiting`, user);
        if (data.data.statusCode == 405) {
          dispatch(loginActions.setWaitListFail());
          enableButton();
        } else {
          dispatch(loginActions.setWaitListSuccess());
          enableButton();
        }
      }
    } catch ({ ...error }) {
      console.log(error);
    }
  };
export const getUserCount = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`user/getUserCount`);
    dispatch(loginActions.setUserCount(data.data));
  } catch ({ ...error }) {
    console.log(error);
  }
};

export const updateName = (user, name) => async (dispatch) => {
  try {
    const { data } = await axios.post("userName/" + user.userid, {
      name: name,
    });
    if (data.statusCode == 200) {
      dispatch(loginActions.setName({ name: name }));
      dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    dispatch(loginActions.setLoginError({ loggedIn: false, error }));
  }
};

export const updateReferral = (user, referral) => async (dispatch) => {
  try {
    const { data } = await axios.post("userReferral/" + user.userid, {
      referral: referral,
    });
    if (data.statusCode == 200) {
      dispatch(loginActions.setReferral({ referral: referral }));
      dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    dispatch(loginActions.setLoginError({ loggedIn: false, error }));
  }
};

export const checkoutUpdate =
  (cart, user, orderType, deliveryFee) => async (dispatch) => {
    try {
      const { data } = await axios.post("checkout/update/" + user.userid, {
        cart: cart,
        orderType: orderType,
        deliveryFee: deliveryFee,
      });
      if (data && data.statusCode == 200) {
        dispatch(loginActions.setTotalDishesBooked(cart.length));
        //TODO: empty local cart
        dispatch(loginActions.setBooking({ booking: data.data }));
        dispatch(bottomBarActions.loadTab({ tabName: "orderSucessfull" }));
      } else if (data && data.statusCode == 400) {
        console.log("error");
        //TO-DO: show error page
      }
    } catch ({ ...error }) {
      console.log(error);
      //TO-DO: show error page
    }
  };

export const updateOrder = (order, deliveryFee) => async (dispatch) => {
  try {
    const { data } = await axios.post("order/update/" + order._id, {
      order: order,
      deliveryFee: deliveryFee,
    });
    if (data && data.statusCode == 200) {
      dispatch(loginActions.setOrderDetails(data.data));
      dispatch(bottomBarActions.loadTab({ tabName: "orderSucessfull" }));
      dispatch(loginActions.setSelectedOrder(null));
    } else if (data && data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const addTempUser = (tempUser) => async (dispatch) => {
  try {
    const { data } = await axios.post("/tempUser", {
      tempUser: tempUser,
    });
    if (data && data.statusCode == 200) {
      console.log("temp_user_created");
    } else if (data && data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const checkout =
  (
    cart,
    user,
    email,
    address,
    orderType,
    deliveryFee,
    deliveryDistance,
    deliveryTime,
    totalCost,
    packagingFee,
    gstFee,
    instructionText,
    scheduleTime,
    billingAmount,
    paymentMode,
    paymentId
  ) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post("checkout/" + user.userid, {
        email: email,
        cart: cart,
        address: address,
        orderType: orderType,
        deliveryFee: deliveryFee,
        deliveryDistance: deliveryDistance,
        deliveryTime: deliveryTime,
        totalCost: totalCost,
        packagingFee: packagingFee,
        gstFee: gstFee,
        instructionText : instructionText || "",
        scheduleTime: scheduleTime || "NOW",
        billingAmount: billingAmount,
        paymentMode: paymentMode,
        paymentId: paymentId
      });
      if (data && data.statusCode == 200) {
        dispatch(
          loginActions.setBookingDetails({
            booking: data.data,
            email: email,
            dishesBooked: cart.length,
          })
        );
        dispatch(loginActions.setOrderDetails(data.data));
        dispatch(cartAction.emptyCartData({ isLoading: false }));
        // dispatch(chefActions.onBackClick());
        dispatch(bottomBarActions.hideMenu());
        dispatch(cartAction.setIsAddressOptionsVisible(true));
        dispatch(bottomBarActions.loadTab({ tabName: "orderSucessfull" }));
        localStorage.removeItem("checkoutData")
        //TODO: empty local cart
      } else if (data && data.statusCode == 400) {
        console.log("error");
        //TO-DO: show error page
      }
    } catch ({ ...error }) {
      console.log(error);
      //TO-DO: show error page
    }
  };

  export const updateOrderAction = (orderId, payload) => async (dispatch) => {
    try {
      const { data } = await axios.post("order/updateStatus/" + orderId, payload);
      if (data && data.statusCode === 200) {
        dispatch(loginActions.setUpdatedBooking(data.data));
        return Promise.resolve(); // Resolve the promise when the update is successful
      } else if (data && data.statusCode === 400) {
        console.log("error");
        // TO-DO: Handle error
        return Promise.reject("Update failed"); // Reject the promise on error
      }
    } catch (error) {
      console.log(error);
      // TO-DO: Handle error
      return Promise.reject("Update failed"); // Reject the promise on error
    }
  };
  

export const updateUser = (user, menuId, type) => async (dispatch) => {
  try {
    let menuIdsOpened = [];
    menuIdsOpened = menuIdsOpened.concat(user.menuIdsOpened);
    menuIdsOpened.push(menuId);
    let menuOpens = user.menuOpens;
    let userUpdates = {
      menuOpens: menuOpens + 1,
      menuIdsOpened: menuIdsOpened,
    };
    const payload =  type === "token" ? {firebaseToken:menuId} : userUpdates
    const { data } = await axios.post("user/" + user.userid, payload);
    if (data.statusCode == 200) {
      if(type === "token"){
        dispatch(loginActions.setMenuOpenDetails({...userUpdates, firebaseToken: data.data.firebaseToken}));
      }else{
        dispatch(loginActions.setMenuOpenDetails(userUpdates));
      }
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const updateExploreDishes = (userid, payload) => async (dispatch) => {
  try {
    const { data } = await axios.post("user/" + userid, payload);
    if (data.statusCode == 200) {
      dispatch(loginActions.setExploredDishesIds(data.data));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const updateDishesCheckedInfo = (userid, payload) => async (dispatch) => {
  try {
    const { data } = await axios.post("user/" + userid, payload);
    if (data.statusCode == 200) {
      dispatch(loginActions.setCheckedDishesInfoIds(data.data));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const updatePausedDishesInfo = (userid, payload) => async (dispatch) => {
  try {
    const { data } = await axios.post("user/" + userid, payload);
    if (data.statusCode == 200) {
      dispatch(loginActions.setPausedDishesIds(data.data));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const updateDishPrice =
  (
    cost,
    ingredientCost,
    fuelCost,
    packagingCost,
    deliveryCost,
    dishBand,
    setCurrentPrice,
    calculateTotal,
    dishId,
    isRemovedDish,
    isFromCart,
    maxDishes,
    totalDishesBooked,
    isAddedOnDiscount,
    orderPriceIds,
    dealPrice,
    currentDishPrice
  ) =>
  async (dispatch) => {
    try {

      let discountType = isAddedOnDiscount
      ? "deepDiscount"
      : isFromCart
      ? "normal"
      : maxDishes > totalDishesBooked ? "deepDiscount" : "normal";
      
      // const priceData = await axios.get(
      //   "menu/dish/" + Number(cost) + "/" + ingredientCost + "/" + fuelCost + "/" + dishBand + "/" + discountType + "/" + packagingCost + "/" + deliveryCost
      // );
      // if (priceData.status == 200) {
        const currentPrice = currentDishPrice;
        if (setCurrentPrice) setCurrentPrice(currentPrice);
        if (!isFromCart) return;
        if(orderPriceIds?.current.includes(dishId)) return
        orderPriceIds?.current.push(dishId);
        const dealPriceAmount = currentPrice;
        if (calculateTotal) {
          dispatch(
            cartAction.setTotalCost({
              dealPrice: dealPriceAmount,
              dishId: dishId,
              maxDishes: maxDishes,
              totalDishesBooked: totalDishesBooked,
            })
          );
        } else if (isRemovedDish) {
          dispatch(
            cartAction.removeDishCost({
              dealPrice: dealPriceAmount,
              dishId: dishId,
            })
          );
        }
        return currentPrice;
        //dispatch(dishActions.updatePrice(priceData.data.data));
      // } else if (priceData.status == 400) {
      //   console.log("error");
      //   //TO-DO: show error page
      // }
    } catch (error) {
      console.log(error);
      //TO-DO: show error
    }
  };

export const updateDishCheckedInUser = (user, dishId) => async (dispatch) => {
  try {
    let dishIdsChecked = [];
    dishIdsChecked = dishIdsChecked.concat(user.dishIdsChecked);
    dishIdsChecked.push(dishId);
    let dishPriceChecked = dishIdsChecked.length;
    let userUpdates = {
      dishPriceChecked: dishPriceChecked,
      dishIdsChecked: dishIdsChecked,
    };
    const { data } = await axios.post("user/" + user.userid, userUpdates);
    if (data.statusCode == 200) {
      dispatch(loginActions.setDishPriceCheckedDetails(userUpdates));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const sendOTP =
  ({ mobileNumber, otp }) =>
  async (dispatch) => {
    try {
      const response = await axios({
        baseURL: MSG91_URL,
        url: "otp",
        params: {
          template_id: MSG91_SMS_TEMPLATE_ID,
          authkey: MSG91_AUTH_KEY,
          mobile: mobileNumber,
          otp,
        },
      });
      if (response.statusCode === 200) {
        // TODO: handle success response
        console.log("opt response - ", response);
      }
    } catch ({ ...error }) {
      // TODO: handle error response
      console.log("otp error - ", error);
    }
  };

export const confirmOrder =
  ({ mobileNumber }) =>
  async (dispatch) => {
    try {
      const response = await axios({
        method: "POST",
        baseURL: MSG91_URL,
        url: "flow",
        headers: {
          authkey: MSG91_AUTH_KEY,
          "content-type": "application/JSON",
        },
        data: {
          flow_id: MSG91_SMS_CHECKOUT_TEMPLATE_ID,
          sender: MSG91_SMS_SENDER_ID,
          short_url: "1",
          mobiles: mobileNumber,
        },
      });
      if (response.statusCode === 200) {
        // TODO: handle success response
        console.log(
          "We at Cuirato deeply appreciate that you've pre-ordered your fave dishes. We're geared with platefuls of passion to bring you authentic cuisine prepared by pro chefs real soon!",
          response
        );
      }
    } catch ({ ...error }) {
      // TODO: handle error response
      console.log("Order not placed - ", error);
    }
  };
