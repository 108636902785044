import React, { useState } from "react";
import ChefCuisineBadge from "./ChefCuisineBadge";

import ChefName from "./ChefName";
import ChefProfilePic from "./ChefProfilePic";
import ChefCardInfo from "./ChefCardInfo";
import "../base.css";
import "./chef-cards.css";
import { useDispatch, useSelector } from "react-redux";
import { setStoryData } from "../../store/storieAction";
import { bottomBarActions } from "../../store/bottomBarSlice";
import mixpanel from "mixpanel-browser";
import { Modal } from "antd";
import ChefOpeningHour from "./ChefOpeningHour";
import { formatScheduleTime, getDisplayChefTimeLabel, getTodayDateValue } from "../../utils/timeHelper";

const ChefCardProfile = (props) => {
  const { chefWaitTime } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const item = props.item;
  const labels = props.labels;
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const freeMenuidsOpened = useSelector(
    (state) => state.auth.freeMenuidsOpened
  );
  var menuId = useSelector((state) => state.chef.categories[item._id]);
  if (menuId) {
    menuId = menuId["menuId"];
  }

  const onClickProfile = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "storie" }));
    dispatch(
      setStoryData(
        [
          {
            img: props.item.profileImg,
            name: props.item.name,
            cuisine: props.item.cuisine,
          },
        ],
        "chef"
      )
    );
    mixpanel.track("chef-story-clicked");
  };
  
  const formatTime = formatScheduleTime(item?.schedules);
  
  const todayChefSchedule = getTodayDateValue(item?.schedules);
  
  const textOutput = getDisplayChefTimeLabel(todayChefSchedule);

  return (
    <div className="row profile">
      <div className="col-6 profile-left">
        <ChefCuisineBadge cuisine={item.cuisine} />
        <ChefName name={item.name} shift={item.shift} leave={item.leave} isSwitchOff={item?.isSwitchOff} chefWaitTime={chefWaitTime} schedules={item?.schedules} breakShift={item.breakShift} />
        <ChefCardInfo
          img={labels.timing}
          header={textOutput}
          desc={"Timings"}
          setModalOpen={setModalOpen}
        />
      </div>
      <ChefProfilePic
        img={item.profileImg}
        onClick={onClickProfile}
        chefWaitTime={chefWaitTime}
        unlocked={
          user.menuIdsOpened.includes(menuId) ||
          freeMenuidsOpened.includes(menuId)
        }
      />
      {item.qualifications && (
        <div className="col-12 profile-bottom">
          <div className="row">
            <ChefCardInfo
              img={labels.experienceImage}
              header={item.totalExperience + " years"}
              desc={labels.experienceDesc}
            />

            <ChefCardInfo
              img={labels.success}
              header={item.successRate + "% (" + item.dishes + " dishes served)"}
              desc={"Success rate"}
            />
          </div>

        </div>
      )}
       <Modal
        title={<span style={{color:"#800020"}}>Chef's Opening Hours</span>}
        centered
        open={modalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        {formatTime.map((time, index) =>(
          <ChefOpeningHour key={index} time={time} />
        ))}

      </Modal>
    </div>
  );
};

export default ChefCardProfile;
