import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import BottomBar from '../BottomBar';
import "./explore.css"
import ExploreStory from './exploreStory';
import Stories from "react-insta-stories";
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";

import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";

import SwiperCore, { EffectFade } from "swiper/core";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/effect-cube/effect-cube.min.css";
import { useEffect, useState } from "react";
import VegChoice from './VegChoice';
import { updateExploreDishes, updatePausedDishesInfo } from '../../store/loginActions';

SwiperCore.use([EffectFade]);


const Explore = (props) => {
  const foodChoice = localStorage.getItem("foodChoice");
  const [foodChoiceState, setFoodChoiceState] = useState(foodChoice || "");
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const viewportHeight = window.innerHeight;
  const screenHeight = isIOS ? viewportHeight : viewportHeight - 58;
  const categories = useSelector((state) => state.chef.categories);
  const user = useSelector(({ auth }) => auth.user);
  const startTimestampRef = useRef(null);

  const dispatch = useDispatch();

  const catName = useSelector((state) => state.chef.categoryName);

  // const chefId = props.dishes.map((item) => item?.chefId);

  const [swiper, setSwiper] = useState(null);
  let storiesList = [];
  const categoriesWithClass = categories["63f7a4af22229172032a8417"]?.filter((a) => a.type !== "daily")?.filter((category) => {
    if (category.img)
      return {
        ...category,
        ringClass: "ring-" + category.color,
      };
  });

  let storyNum = categoriesWithClass?.findIndex(
    (category) => category.name === catName
  );
  let storySlideNumber = 0;

  const [my_swiper, set_my_swiper] = useState({});

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(storyNum)
    }
  }, [swiper])

  const swipeToNextSlide = () => {
    if (swiper) {
      const curInd = swiper.activeIndex;
      if ((curInd === categoriesWithClass.length - 1)) {
        return false;
      } else {
        return true;
      }
    }
  }


  const getStories = (catDishes, isActive, dish, index, categoryId) => {
    try {
      const dishImgs = dish.stories.map((item) => item?.img);
      const descriptions = dish.stories.map((item) => item?.description);
      const headers = dish?.name;
      const price = dish?.price
      const chefId = dish?.chefId
      const description = dish?.description
      const chefImgs = dish?.chefImg
      const chefNames = dish?.chefName
      let storyContent = [];
      const labels = [
        { key: "INGRIDENT", desc: "The list of ingredients provides a comprehensive compilation of the essential elements required to prepare the dish, encompassing diverse flavors, textures, and nutritional components to create a harmonious and satisfying culinary experience." },
        { key: "NUTRITION", desc: "The nutrition information serves as a valuable guide, offering insights into the dish's caloric content, macro and micronutrient composition, enabling individuals to make informed choices that align with their dietary preferences and health goals." },
        { key: "XYZ DATA", desc: "XYZ data could represent specific details or steps unique to the recipe, such as cooking techniques, special instructions, or distinct elements that contribute to the dish's identity and character." },
        { key: "ABC DATA", desc: "ABC data may include additional information relevant to the recipe, such as serving suggestions, cultural significance, or variations, providing a more holistic understanding of the dish beyond its core ingredients and nutritional profile." },
      ];
      [...labels].forEach((img, i) => {
        if (img) {
          storyContent.push({
            content: ({ action, isPaused }) => {
              if (!isActive) {
                setTimeout(() => {
                  action('pause');
                }, 0);
              }
              // else if (!isPaused) {
              //   setTimeout(() => {
              //     action('play');
              //   }, 0);
              // }
              return (
                <ExploreStory
                  key={i + "" + index + "" + categoryId}
                  img={dish.stories[0].img}
                  action={action}
                  labels={labels}
                  labelDesc={img}
                  isPaused={isPaused}
                  isActive={isActive}
                  headers={headers}
                  name={catDishes?.name}
                  categoryImg={catDishes.img ? S3_MENU_THUMBNAIL_FOLDER + catDishes.img : ""}
                  price={price}
                  chefId={chefId}
                  description={descriptions[i] || description}
                  chefImg={chefImgs}
                  chefName={chefNames}
                  categoryId={catDishes._id}
                />
              );
            },
          })
        }

      });
      storiesList = storyContent;
      return storyContent;
    } catch (error) {
      console.log("Error");
    }
  };

  if (!categoriesWithClass || !categoriesWithClass.length) return (<div className="splash-screen">
    <img src="cuirato-main.png" alt="loading..." />
  </div>)
  const selectedFoodChoice = foodChoiceState === "VEG" ? ["veg"] : foodChoiceState === "NONVEG" ? ["non veg", "veg", "egg"] : ["non veg", "veg", "egg"]
  return (
    <div>
      {!["VEG", "NONVEG"].includes(foodChoiceState) ? <div className='veg-choice-wrapper'>
        <VegChoice setFoodChoiceState={setFoodChoiceState} />
      </div> : <Swiper
        direction={'vertical'}
        pagination={{
          clickable: true,
        }}
        height={screenHeight}
        onInit={(ev) => {
          set_my_swiper(ev)
        }}
        onSwiper={(s) => setSwiper(s)}
        className='explore-swiper-wrapper'
      >
        {categoriesWithClass.map((categoryWithClass, i) => {
          if (categoryWithClass.dishes[0].stories?.[0]) {
            return categoryWithClass.dishes.filter((a) => selectedFoodChoice.includes(a.type)).map((dish, categoryId) => {
              return (
                <SwiperSlide key={i + "" + categoryId}>
                  {({ isActive }) => (
                    <Stories
                      key={i + "" + categoryId}
                      isPaused={true}
                      stories={getStories(categoryWithClass, isActive, dish, i, categoryId)}
                      defaultInterval={5000}
                      width="100vw"
                      height={screenHeight + 10}
                      progressContainerStyles={{ top: '2px' }}
                      onStoryStart={(s) => {
                        if (isActive) {
                          startTimestampRef.current = performance.now();
                          storySlideNumber = s;
                        }
                      }}
                      onStoryEnd={(s) => {
                        const dishId = dish._id;
                        const exploredDishesIdsLocal = JSON.parse(localStorage.getItem("@user") || "{}")["exploredDishesIds"] || [];
                        const userexploredDishesIds = user.exploredDishesIds || [];
                        const allDishesIds = [...exploredDishesIdsLocal, ...userexploredDishesIds, dishId];
                        const allDishesIdsUniqueArray = [...new Set(allDishesIds)];
                        const payload = { exploredDishesIds: allDishesIdsUniqueArray };
                        dispatch(updateExploreDishes(user.userid, payload));
                        if (isActive) {
                          storySlideNumber = s;
                        }
                        if (startTimestampRef.current !== null) {
                          const endTimestamp = performance.now();
                          const timeDifference = endTimestamp - startTimestampRef.current;
                          if (timeDifference > 5300) {
                            const pausedDishesIds = user.pausedDishesIds || [];
                            const allDishesPausedIds = [...new Set([...pausedDishesIds, dishId])] 
                            const payload = { pausedDishesIds: allDishesPausedIds };
                            dispatch(updatePausedDishesInfo(user.userid, payload));
                          }
                          startTimestampRef.current = performance.now(); // Reset the start timestamp
                        }
                        if (s === 4 - 1) {
                          const canSwipe = swipeToNextSlide();
                          if (canSwipe) {
                            storySlideNumber = 0;
                            my_swiper.slideNext();
                          } else {
                            dispatch(chefActions.onStoryBackClick());
                          }
                        }
                      }}
                    />
                  )}
                </SwiperSlide>
              );
            })
          }
        })}
      </Swiper>
      }
      <BottomBar isExplore />
    </div>
  )
}

export default Explore
