import { getToken } from "@firebase/messaging";
import moment from "moment";


export const getUniqueArray = (array) => {
  const uniqueIds = [];
  return array.filter((obj) => {
    if (!uniqueIds.includes(obj._id)) {
      uniqueIds.push(obj._id);
      return true;
    }
    return false;
  });
};

export const isWebApp = () => {
  const isStandalone =
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone === true;
  const isHTTPS = window.location.protocol === "https:";
  return true;
  return !isStandalone && isHTTPS;
};

export const getExactTime = (timestamp, moreMinutes, isOrderScheduled) => {
  const targetTime = new Date(timestamp);
  if (moreMinutes) {
    targetTime.setMinutes(targetTime.getMinutes() + moreMinutes);
  }
  const momentTimeFormate = isOrderScheduled ? moment(targetTime).format("MMM D, h:mm A") : moment(targetTime).format("h:mm A")
  return `${momentTimeFormate}`;
};

export const getOrderStatusLabel = (orderStatus) => {
  switch (orderStatus) {
    case "CREATED":
      return "Order Placed";
    case "CONFIRMED":
      return "Order Confirmed";
    case "READY":
      return "Order Ready";
    case "PICKED_UP":
      return "Order on the way";
    case "DELIVERED":
      return "Delivered";
    default:
      return "Preparing";
  }
};

export const combineTheDate = (date1, date2) => {
  const year = date1?.year();
  const month = date1?.month();
  const day = date1?.date();

  // Extract time components from date2
  const hours = date2?.getHours();
  const minutes = date2?.getMinutes();
  const seconds = date2?.getSeconds();

  // Create a new Date object with combined date and time
  const newDate = new Date(year, month, day, hours, minutes, seconds);
  return newDate;
};


export const isIOSDevice = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const capitalizeFirstLetter = (string = "") => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const mergeCartData = (stateCartData, payloadCartData) => {
    return payloadCartData.map((data) => {
        const existingIndex = stateCartData.findIndex(
            (stateItem) => stateItem._id === data._id
        );
        if (existingIndex !== -1) {
            if (stateCartData[existingIndex]?.dealPrice) {
                data.dealPrice = stateCartData[existingIndex]?.dealPrice;
            }
        }
        return data;
    });
};
