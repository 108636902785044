import { createSlice } from "@reduxjs/toolkit";

const setUserToLocalStorage = (user) => {
  localStorage.setItem("@user", JSON.stringify(user));
};

const getUserToLocalStorage = (field) => {
  return JSON.parse(localStorage.getItem("@user") || "{}")[field] || "";
};

const setToLocalStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

const getFromLocalStorage = (name) => {
  return JSON.parse(localStorage.getItem(name));
};

const initialState = {
  loggedIn: getUserToLocalStorage("loggedIn") || false,
  error: false,
  user: {
    userid: getUserToLocalStorage("userid"),
    username: getUserToLocalStorage("username"),
    phonenumber: getUserToLocalStorage("phonenumber"),
    menuOpens: getUserToLocalStorage("menuOpens"),
    menuIdsOpened: getUserToLocalStorage("menuIdsOpened"),
    dishPriceChecked: getUserToLocalStorage("dishPriceChecked"),
    dishIdsChecked: getUserToLocalStorage("dishIdsChecked"),
    exploredDishesIds: getUserToLocalStorage("exploredDishesIds"),
    checkedDishesInfoIds: getUserToLocalStorage("checkedDishesInfoIds"),
    pausedDishesIds: getUserToLocalStorage("pausedDishesIds"),
    email: getUserToLocalStorage("email"),
    area: getUserToLocalStorage("area"),
    booking: getUserToLocalStorage("booking"),
    orderReviewWriteInfo: getUserToLocalStorage("orderReviewWriteInfo"),
    referral: getUserToLocalStorage("referral"),
    referralCode: getUserToLocalStorage("referralCode"),
    maxDishes: getUserToLocalStorage("maxDishes"),
    maxChefs: getUserToLocalStorage("maxChefs"),
    maxDishPrices: getUserToLocalStorage("maxDishPrices"),
    totalDishesBooked: getUserToLocalStorage("totalDishesBooked"),
    freeDishes: getUserToLocalStorage("freeDishes"),
    // address: getUserToLocalStorage("address") || [],
    addressLine1: getUserToLocalStorage("addressLine1") || [],
  },
  getName: false,
  getReferral: false,
  freeMenuOpens: getFromLocalStorage("freeMenuOpens") || 0,
  freeMenuidsOpened: getFromLocalStorage("freeMenuidsOpened") || [],
  waitListSuccess: false,
  waitListFail: false,
  tourState: getFromLocalStorage("tourCompleted") || false,
  location: getFromLocalStorage("location") || null,
  isLocationEnabled: getFromLocalStorage("isLocationEnabled") || false,
  coords: getFromLocalStorage("coords") || null,
  isServiceable: getFromLocalStorage("isServiceable") === false ? getFromLocalStorage("isServiceable") : true,
  isServiceableAlet: getFromLocalStorage("isServiceableAlet") || false,
  selectedAddress: getFromLocalStorage("selectedAddress") || {},
  userCount: 182,
  orderDetails: getFromLocalStorage("orderDetails") || [],
  singleOrderHistoryDetails : null ,
  selectedOrder: null,
  deliveryDetails: getFromLocalStorage("deliveryDetails") || {},
  unserviceableModalOnCart: getFromLocalStorage("unserviceableModalOnCart") || false,
  farAwayModalOnCart: getFromLocalStorage("farAwayModalOnCart") || false,
  minimumAmount: getFromLocalStorage("minimumAmount") || 0,
  openPaymentToken: null,
};

const loginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    setLoggedIn(state, action) {
      setUserToLocalStorage({ ...action.payload.userData, loggedIn: true });
      state.loggedIn = action.payload.loggedIn;
      state.user = action.payload.userData;
      return state;
    },
    setGetName(state, action) {
      setUserToLocalStorage({ ...action.payload.userData, loggedIn: true });
      state.getName = true;
      state.user = action.payload.userData;
      return state;
    },
    setGetReferral(state) {
      state.getReferral = true;
      return state;
    },
    setName(state, action) {
      state.user.username = action.payload.name;
      setUserToLocalStorage({ ...state.user, loggedIn: true });
    },
    setReferral(state, action) {
      state.user.referral = action.payload.referral;
      setUserToLocalStorage({ ...state.user, loggedIn: true });
    },
    setBooking(state, action) {
      state.user.booking = action.payload.booking;
      setUserToLocalStorage({ ...state.user, loggedIn: true });
    },
    setUpdatedBooking(state, action) {
      const index = state.user.booking.findIndex(item => item._id === action.payload._id);
      const bookingClone = [...state.user.booking]
      if (index !== -1) {
        bookingClone[index] = action.payload;
      }
      state.user.booking = bookingClone;
      setUserToLocalStorage({ ...state.user, loggedIn: true });
    },
    setOrderReviewWriteInfo(state, action) {
      state.user.orderReviewWriteInfo = action.payload.orderReviewWriteInfo;
    },
    setBookingDetails(state, action) {
      state.user.booking = action.payload.booking;
      state.user.totalDishesBooked =
        state.user.totalDishesBooked + action.payload.dishesBooked;
      state.user.email = action.payload.email;
      state.user.address = action.payload.address;
      setUserToLocalStorage({ ...state.user, loggedIn: true });
    },
    setLoginError(state, action) {
      state.error = action.payload.error;
      state.loggedIn = action.payload.loggedIn;
      return state;
    },
    incrementFreeMenuOpens(state, action) {
      state.freeMenuOpens = state.freeMenuOpens + 1;
      setToLocalStorage("freeMenuOpens", state.freeMenuOpens);
    },
    setFreeMenuId(state, action) {
      state.freeMenuidsOpened.push(action.payload.menuId);
      setToLocalStorage("freeMenuidsOpened", state.freeMenuidsOpened);
    },
    setTotalDishesBooked(state, action) {
      state.user.totalDishesBooked =
        state.user.totalDishesBooked + action.payload;
      setUserToLocalStorage({ ...state.user, loggedIn: true });
    },
    openFreeMenu(state, action) {
      state.freeMenuOpens = state.freeMenuOpens + 1;
      state.freeMenuidsOpened.push(action.payload);
      setToLocalStorage("freeMenuOpens", state.freeMenuOpens);
      setToLocalStorage("freeMenuidsOpened", state.freeMenuidsOpened);
    },
    setMenuOpenDetails(state, action) {
      state.user.menuOpens = action.payload.menuOpens;
      state.user.menuIdsOpened = action.payload.menuIdsOpened;
      if(action.payload.firebaseToken){
        state.user.firebaseToken = action.payload.firebaseToken;
      }
      setUserToLocalStorage({ ...state.user });
    },
    setDishPriceCheckedDetails(state, action) {
      state.user.dishPriceChecked = action.payload.dishPriceChecked;
      state.user.dishIdsChecked = action.payload.dishIdsChecked;
      setUserToLocalStorage({ ...state.user });
    },
    setExploredDishesIds(state, action) {
      state.user.exploredDishesIds = action.payload.exploredDishesIds;
      setUserToLocalStorage({ ...state.user });
    },
    setCheckedDishesInfoIds(state, action) {
      state.user.checkedDishesInfoIds = action.payload.checkedDishesInfoIds;
      setUserToLocalStorage({ ...state.user });
    },
    setPausedDishesIds(state, action) {
      state.user.pausedDishesIds = action.payload.pausedDishesIds;
      setUserToLocalStorage({ ...state.user });
    },
    setWaitListSuccess(state) {
      state.waitListSuccess = true;
      state.waitListFail = false;
    },
    setWaitListFail(state) {
      state.waitListFail = true;
      state.waitListSuccess = false;
    },
    setTourState(state) {
      state.tourState = true;
      setToLocalStorage("tourCompleted", true);
    },
    setLocation(state, { payload }) {
      state.location = payload;
      setToLocalStorage("location", payload);
    },
    setIsLocationEnabled(state) {
      state.isLocationEnabled = true;
      setToLocalStorage("isLocationEnabled", true);
    },
    setAddress(state, action) {
      state.user.addressLine1.push(action.payload);
      setUserToLocalStorage({ ...state.user });
    },
    setCoords(state, { payload }) {
      state.coords = payload;
      setToLocalStorage("coords", payload);
    },
    setIsServiceable(state, { payload }) {
      state.isServiceable = payload;
      setToLocalStorage("isServiceable", payload);
    },
    setIsServiceableAlert(state, { payload }) {
      state.isServiceableAlert = payload;
      setToLocalStorage("isServiceableAlert", payload);
    },
    setDeliveryDetails(state, { payload }) {
      state.deliveryDetails = payload;
      setToLocalStorage("deliveryDetails", payload);
    },
    setUnserviceableModalOnCart(state, { payload }) {
      state.unserviceableModalOnCart = payload;
      setToLocalStorage("unserviceableModalOnCart", payload);
    },
    setFarAwayModalOnCart(state, { payload }) {
      state.farAwayModalOnCart = payload;
      setToLocalStorage("farAwayModalOnCart", payload);
    },
    setMinimumAmount(state, { payload }) {
      state.minimumAmount = payload;
      setToLocalStorage("minimumAmount", payload);
    },
    setOpenPaymentToken(state, { payload }) {
      if(payload){
        state.openPaymentToken = payload.data.data.data.id;
      }else{
        state.openPaymentToken = null;
      }
      
    },
    setSelectedAddress(state, { payload }) {
      state.selectedAddress = payload;
      setToLocalStorage("selectedAddress", payload);
    },
    setOrderDetails(state, { payload }) {
      state.orderDetails = payload;
      setToLocalStorage("orderDetails", payload);
    },
    setSingleOrderHistoryDetails(state, { payload }) {
      state.singleOrderHistoryDetails = payload;
    },
    setSelectedOrder(state, { payload }) {
      state.selectedOrder = payload;
    },
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice;
